import QuestData from '../../data/all_quests.json';

export const processUpdatedQuests = (storedQuests, storedVersion) => {
  const updatedQuests = JSON.parse(JSON.stringify(QuestData['quests']));
  for (const newQuest of updatedQuests) {
    if (Array.isArray(storedQuests)) {
      let searchName = newQuest.name;
      // if (storedVersion <= 19) {
      //   if (searchName === 'The Alchemist’s Pursuit') {
      //     searchName = 'Castle Clash';
      //   } else if (searchName === 'The Unseen') {
      //     searchName = 'Braving The Shadows';
      //   } else if (searchName === 'Cursed Lands') {
      //     searchName = 'Green-Skinned Scum';
      //   } else if (searchName === 'Secret Omens') {
      //     searchName = 'Mystic Insights';
      //   } else if (searchName === 'Unseen') {
      //     searchName = 'Faith In The Unknown';
      //   } else if (searchName === 'Glimpse of the Dark') {
      //     searchName = 'Tide Of Darkness';
      //   } else if (searchName === 'Buggy Bonds') {
      //     searchName = 'My Favorite Fleshy';
      //   } else if (searchName === 'My Favorite Fleshy') {
      //     searchName = 'Buggy Bonds';
      //   } else if (searchName === 'Piercing the Chill') {
      //     searchName = 'Hidden Truths';
      //   } else if (searchName === 'Too Cold') {
      //     searchName = 'Brutish Truths';
      //   } else if (searchName === 'Precise as Ice') {
      //     searchName = 'Silent Entry';
      //   } else if (searchName === 'Frayed Secrets') {
      //     searchName = 'Elegance in Execution';
      //   } else if (searchName === 'In Shambles') {
      //     searchName = 'Sprucing Up';
      //   } else if (searchName === 'Loyalty Remains') {
      //     searchName = 'The Elite And Their Secrets';
      //   } else if (searchName === 'Guiding Light') {
      //     searchName = 'Not Friendly';
      //   } else if (searchName === 'Not Friendly') {
      //     searchName = 'Guiding Light';
      //   } else if (searchName === 'Ceremonial Cup') {
      //     searchName = 'Bundle of Bangles';
      //   } else if (searchName === 'A Noble’s Dark Ties') {
      //     searchName = 'Golden Dreams';
      //   } else if (searchName === 'Royal Secrets') {
      //     searchName = 'Colossal Takedown';
      //   } else if (searchName === 'Lost to Time') {
      //     searchName = 'Mystical Gems';
      //   } else if (searchName === 'Anger Management') {
      //     searchName = 'Battle Forge';
      //   } else if (searchName === 'Silent Witnesses') {
      //     searchName = 'An Oath Unbroken';
      //   } else if (searchName === 'Stone Cold') {
      //     searchName = "Warrior's Reckoning";
      //   } else if (searchName === 'Bright Ideas') {
      //     searchName = 'Pelt Pursuit';
      //   }
      // }

      if (storedVersion < 27) {
        const questMap = {
          'Hidden Within The Lines':
            'Text_DesignData_Quest_Alchemist_Title_04_EA_05',
          'Test Run': 'Text_DesignData_Quest_Alchemist_Title_05_EA_05',
          'Hocus Pocus': 'Text_DesignData_Quest_Alchemist_Title_06_EA_05',
          'Lobster Red': 'Text_DesignData_Quest_Armourer_Title_04_EA_05',
          'Reflections of the Cursed':
            'Text_DesignData_Quest_FortuneTeller_Title_04_EA_05',
          'Frostbitten Ears':
            'Text_DesignData_Quest_FortuneTeller_Title_05_EA_05',
          'In Shambles': 'Text_DesignData_Quest_Tailor_Title_06_EA_05',
          'Guiding Light': 'Text_DesignData_Quest_TheCollector_Title_04_EA_05',
          'Peculiar Collections':
            'Text_DesignData_Quest_TheCollector_Title_06_EA_05',
          'Ceremonial Cup': 'Text_DesignData_Quest_Treasurer_Title_07_EA_05',
          'Farm It or Buy It':
            'Text_DesignData_Quest_Weaponsmith_Title_04_EA_05',
          'Explosive Endeavors':
            'Text_DesignData_Quest_Woodsman_Title_05_EA_05',
        };

        if (questMap[searchName]) {
          searchName = questMap[searchName];
        }
      }
      if (storedVersion < 28) {
        const questMap = {
          'Mixing Things Up': 'Text_DesignData_Quest_Alchemist_Title_07_EA_05',
          'Death In The Air': 'Text_DesignData_Quest_Alchemist_Title_08_EA_05',
          'Death Awakens': 'Text_DesignData_Quest_Alchemist_Title_09_EA_05',
          'Ruined Resources': 'Text_DesignData_Quest_Armourer_Title_07_EA_05',
          'Ale-Soaked Insights':
            'Text_DesignData_Quest_Armourer_Title_08_EA_05',
          'Cloaked Secrets': 'Text_DesignData_Quest_Armourer_Title_09_EA_05',
          'Lights Out': 'Text_DesignData_Quest_FortuneTeller_Title_07_EA_05',
          'Webs of Fate': 'Text_DesignData_Quest_FortuneTeller_Title_08_EA_05',
          'That�s Batty': 'Text_DesignData_Quest_FortuneTeller_Title_09_EA_05',
          Dreamcatcher: 'Text_DesignData_Quest_FortuneTeller_Title_10_EA_05',
          'Self Made Luck':
            'Text_DesignData_Quest_FortuneTeller_Title_11_EA_05',
          'Icy Fortune': 'Text_DesignData_Quest_FortuneTeller_Title_12_EA_05',
          'Shiny Secrets':
            'Text_DesignData_Quest_GoblinMerchant_Title_10_EA_05',
          Gamba: 'Text_DesignData_Quest_GoblinMerchant_Title_11_EA_05',
          'One Eyed Foe': 'Text_DesignData_Quest_GoblinMerchant_Title_12_EA_05',
          'Whispers in the Ruins':
            'Text_DesignData_Quest_Leathersmith_Title_07_EA_05',
          'The Weary Request':
            'Text_DesignData_Quest_Leathersmith_Title_08_EA_05',
          'Frost Aid': 'Text_DesignData_Quest_Surgeon_Title_07_EA_05',
          'Sanctified Healing': 'Text_DesignData_Quest_Surgeon_Title_08_EA_05',
          'Precise as Ice': 'Text_DesignData_Quest_Surgeon_Title_09_EA_05',
          'Cold Shoulder': 'Text_DesignData_Quest_Tailor_Title_07_EA_05',
          'Murmurs Among The Aristocracy':
            'Text_DesignData_Quest_Tailor_Title_08_EA_05',
          'Infiltrating With Accessories':
            'Text_DesignData_Quest_Tailor_Title_09_EA_05',
          'Blessings Be': 'Text_DesignData_Quest_TavernMaster_Title_13_EA_05',
          "Protector's Wrath":
            'Text_DesignData_Quest_TavernMaster_Title_14_EA_05',
          'Confronting The Darkness':
            'Text_DesignData_Quest_TavernMaster_Title_15_EA_05',
          'Cold-Blooded Conflict':
            'Text_DesignData_Quest_TavernMaster_Title_16_EA_05',
          'Collector Of Trinkets':
            'Text_DesignData_Quest_TavernMaster_Title_17_EA_05',
          'Frozen Terror': 'Text_DesignData_Quest_TavernMaster_Title_18_EA_05',
          'Glacial Conquest':
            'Text_DesignData_Quest_TheCollector_Title_07_EA_05',
          'Not Friendly': 'Text_DesignData_Quest_TheCollector_Title_08_EA_05',
          'Morbid Souvenirs':
            'Text_DesignData_Quest_TheCollector_Title_09_EA_05',
          'Chilled Vengeance':
            'Text_DesignData_Quest_TheCollector_Title_10_EA_05',
          'Keeping Warm': 'Text_DesignData_Quest_TheCollector_Title_11_EA_05',
          "Mountain's Inhabitants":
            'Text_DesignData_Quest_TheCollector_Title_12_EA_05',
          'Iron Tight': 'Text_DesignData_Quest_Treasurer_Title_10_EA_05',
          'Something Marvelous':
            'Text_DesignData_Quest_Treasurer_Title_11_EA_05',
          'A Legendary Addition':
            'Text_DesignData_Quest_Treasurer_Title_12_EA_05',
          'Gold Rush': 'Text_DesignData_Quest_Treasurer_Title_13_EA_05',
          'Behind The Stone': 'Text_DesignData_Quest_Treasurer_Title_14_EA_05',
          'Bangle Lord': 'Text_DesignData_Quest_Treasurer_Title_15_EA_05',
          'Something Good In Something Small':
            'Text_DesignData_Quest_Weaponsmith_Title_08_EA_05',
          'Golden Dreams': 'Text_DesignData_Quest_Weaponsmith_Title_09_EA_05',
          'Culling the Enemy':
            'Text_DesignData_Quest_Weaponsmith_Title_10_EA_05',
          'Gathering Progress':
            'Text_DesignData_Quest_Weaponsmith_Title_11_EA_05',
          'Piercing Heat': 'Text_DesignData_Quest_Weaponsmith_Title_12_EA_05',
          'Amidst Peril': 'Text_DesignData_Quest_Woodsman_Title_08_EA_05',
          'Infernal Champions': 'Text_DesignData_Quest_Woodsman_Title_09_EA_05',
          "Ember's Amidst Frost":
            'Text_DesignData_Quest_Woodsman_Title_10_EA_05',
          'Frostbound Carnage': 'Text_DesignData_Quest_Woodsman_Title_11_EA_05',
          'Frostborn Beasts': 'Text_DesignData_Quest_Woodsman_Title_12_EA_05',
        };
        if (questMap[searchName]) {
          searchName = questMap[searchName];
        }
      }

      const oldQuest = storedQuests.find((item) => item.name === searchName);

      if (oldQuest) {
        newQuest['completed'] = oldQuest?.['completed'] === true;
        newQuest['favorited'] = oldQuest?.['favorited'] === true;

        for (const key of Object.keys(oldQuest.objectives)) {
          if (newQuest.objectives[key]) {
            for (const oldTask of oldQuest.objectives[key]) {
              const taskToUpdate = newQuest.objectives[key].find(
                (task) => task.name === oldTask.name,
              );

              if (taskToUpdate) {
                taskToUpdate['countCompleted'] =
                  oldTask.countCompleted != undefined
                    ? oldTask.countCompleted
                    : 0;
              }
            }
          }
        }
      }
    }
  }
  return [...updatedQuests];
};
