import React, { useEffect, useState } from 'react';
import {
  Typography,
  Statistic,
  Card,
  Divider,
  Row,
  FloatButton,
  List,
} from 'antd';
import { DiscordOutlined } from '@ant-design/icons';
import QuestData from '../data/all_quests.json';
import ReactGA from 'react-ga4';
import {
  dateOfQuestInfo,
  latestPatchNotes,
  currentQuestDataVersion,
} from '../data/versionInfo';
const { Title, Paragraph, Text } = Typography;

const Homepage = () => {
  const [questsCompleted, setQuestsCompleted] = useState(0);
  const [numQuests, setNumQuests] = useState(0);

  const numCompletedQuests = () => {
    const storedQuestData = localStorage.getItem('questData');
    if (storedQuestData) {
      const quests = JSON.parse(storedQuestData);
      let completedCount = 0;

      quests.forEach((quest) => {
        // Check if the quest has a completed property and if it's true
        if (quest.completed === true) {
          completedCount++;
        }
      });

      return completedCount;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/',
      title: 'Home page',
    });
  }, []);
  useEffect(() => {
    // Assuming these values are fetched from your backend
    setNumQuests(QuestData['quests'].length);
    const completedQuests = numCompletedQuests();
    setQuestsCompleted(completedQuests);
  }, []);

  return (
    <div className="">
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          tooltip={<div>Discord server</div>}
          icon={<DiscordOutlined />}
          href="https://discord.gg/Czx6xMq5rN"
          target="_blank"
        />
      </FloatButton.Group>
      <div>
        <div className="">
          <Title
            type="primary"
            style={{ textAlign: 'center', marginBottom: '20px' }}
            level={2}
          >
            Dark and Darker Tracker
          </Title>
          <Paragraph style={{ textAlign: 'center' }}>
            A tool built by Dark and Darker players, for Dark and Darker players
          </Paragraph>
        </div>
        <div className={''}>
          <Row className={'center-contents'}>
            <Card style={{ marginBottom: '20px', width: 600 }}>
              <Text strong type="success">
                Quests up to date as of {dateOfQuestInfo}
              </Text>
            </Card>
          </Row>
          {/* <Row className={'center-contents'}>
            <Card style={{ marginBottom: '20px', width: 600 }}>
              <Title level={3} style={{ marginBottom: '10px' }}>
                Current Progress
              </Title>
              <Statistic
                title="Quests Completed"
                value={`${questsCompleted}/${numQuests}`}
              />
            </Card>
          </Row> */}
          <Row className={'center-contents'}>
            <Card style={{ marginBottom: '20px', width: 600 }}>
              <Title level={3} style={{ marginBottom: '10px' }}>
                Recent patch notes
              </Title>
              <List
                size="small"
                // bordered
                dataSource={latestPatchNotes[currentQuestDataVersion]}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Card>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
