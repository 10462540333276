/* eslint-disable react/prop-types */
import {
  Typography,
  Row,
  Col,
  Layout,
  FloatButton,
  Popconfirm,
  Alert,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { json, useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  DiscordOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import ReactGA from 'react-ga4';
import { ConsoleView, isMobile } from 'react-device-detect';
import SorryImage from '../../img/sorry.png';
import { questItemsPath } from '../../data/paths';
import { processUpdatedQuests } from './processUpdatedQuests';
import {
  Button,
  Space,
  Table,
  Switch,
  Input,
  List,
  Avatar,
  Checkbox,
  Card,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import QuestData from '../../data/all_quests.json';
import TaskTableItem from './TaskTableItem';
import { getRarityColor } from '../../shared/text';
import MerchantAvatar from './merchantAvatar';
import { currentQuestDataVersion } from '../../data/versionInfo';
import ItemAvatar from './itemAvatar';
import MapExploreAvatar from './exploreMapPic';
import QuestNotConfirmedWarningIcon from './questNotConfirmedWarningIcon';
import { isAvailable } from './questShared';
const { Title, Paragraph, Text } = Typography;
const { Search } = Input;
const { Content } = Layout;
const tableColumns = ['Name', 'Merchant', 'Type'];

const merchantFilters = [
  {
    text: 'Alchemist',
    value: 'Alchemist',
  },
  {
    text: 'Armourer',
    value: 'Armourer',
  },
  {
    text: 'Fortune Teller',
    value: 'Fortune Teller',
  },
  {
    text: 'Goblin Merchant',
    value: 'Goblin Merchant',
  },
  {
    text: 'Leathersmith',
    value: 'Leathersmith',
  },
  {
    text: 'Surgeon',
    value: 'Surgeon',
  },
  {
    text: 'Tailor',
    value: 'Tailor',
  },
  {
    text: 'Tavern Master',
    value: 'Tavern Master',
  },
  {
    text: 'The Collector',
    value: 'The Collector',
  },
  {
    text: 'Treasurer',
    value: 'Treasurer',
  },
  {
    text: 'Valentine',
    value: 'Valentine',
  },
  {
    text: 'Weaponsmith',
    value: 'Weaponsmith',
  },
  {
    text: 'Woodsman',
    value: 'Woodsman',
  },
];

const createRewardsSection = (record) => {
  return (
    <Row>
      <Col span={12}>
        <Title level={5}>Quest Prerequisites:</Title>
        <Paragraph>
          <Text>{record.preReq ? record.preReq : 'None'}</Text>
        </Paragraph>

        {Object.keys(record.objectives).includes('explore') && (
          <MapExploreAvatar
            width={300}
            name={record.objectives.explore[0].name}
            map={record.objectives.explore[0].map}
          />
        )}
      </Col>
      <Col style={{ textAlign: 'left' }} span={12}>
        <Title level={4}>Rewards</Title>
        <List
          size="small"
          itemLayout="vertical"
          bordered={true}
          dataSource={record.rewards}
          renderItem={(reward, index) => (
            <List.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ItemAvatar name={reward.name} width={35} isReward={true} />
                {reward.count}
                &nbsp;
                {'x'}
                &nbsp;
                <span
                  style={{
                    color: getRarityColor(reward.name),
                  }}
                >
                  {reward.name}
                </span>
              </div>
              {/* // description="Ant Design, a design language for background
              applications, is refined by Ant UED Team" /> */}
            </List.Item>
          )}
        ></List>
      </Col>
    </Row>
  );
};

//  name , merchant, status(completed/ in progress/Locked),
// eslint-disable-next-line react/prop-types
function QuestTracker({
  selectedCharacter,
  characters,
  setAndStoreCharacters,
}) {
  const navigate = useNavigate();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'favorited',
    order: 'ascend', // Default sort order to show true values first
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [questDataArray, setQuestDataArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFilteredQuestData, setSearchFilteredQuestData] = useState([]);
  const [availableFilteredQuestData, setAvailableFilteredQuestData] = useState(
    [],
  );
  const [showAvailableOnly, setShowAvailableOnly] = useState(true);

  const [savedPagination, setSavedPagination] = useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [10, 15, 20, 30, 40, 50, 100, 200],
    showSizeChanger: true,
  });

  // Gather settings from local storage
  useEffect(() => {
    const storedFilteredInfo = localStorage.getItem('filteredInfo');
    const storedExpandedRowKeys = localStorage.getItem('expandedRowKeys');
    const storedShowAvailableOnly = localStorage.getItem('showAvailableOnly');
    const storedPagination = localStorage.getItem('questPagination');

    if (storedPagination) {
      const parsedPagination = JSON.parse(storedPagination);
      setSavedPagination(parsedPagination);
    }

    const selectedCharObj = characters.find(
      (character) => character.name === selectedCharacter,
    );

    if (selectedCharObj?.questData) {
      const storedQuests = selectedCharObj.questData;

      const storedVersion = selectedCharObj.dataVersion;

      if (storedVersion == currentQuestDataVersion) {
        setQuestDataArray(storedQuests);
        setSearchFilteredQuestData(storedQuests);
        setAvailableFilteredQuestData(storedQuests);
      }

      if (storedVersion < 25) {
        // wipe reset all progress
        let newWipeQuests = JSON.parse(JSON.stringify(QuestData['quests']));

        updateQuestData(newWipeQuests);
        setSearchFilteredQuestData(newWipeQuests);
        setAvailableFilteredQuestData(newWipeQuests);
        return;
      }

      const updatedQuests = processUpdatedQuests(storedQuests, storedVersion);

      updateQuestData(updatedQuests);
      setSearchFilteredQuestData(updatedQuests);
      setAvailableFilteredQuestData(updatedQuests);
    } else {
      const copiedQuestData = JSON.parse(JSON.stringify(QuestData['quests']));

      updateQuestData(copiedQuestData);
      setSearchFilteredQuestData(copiedQuestData);
      setAvailableFilteredQuestData(copiedQuestData);
    }

    if (storedFilteredInfo) {
      setFilteredInfo(JSON.parse(storedFilteredInfo));
    }

    if (storedExpandedRowKeys) {
      setExpandedRowKeys(JSON.parse(storedExpandedRowKeys));
    }

    try {
      const storedSortedInfo = JSON.parse(localStorage.getItem('sortedInfo'));
      if (storedSortedInfo) {
        setSortedInfo(JSON.parse(storedSortedInfo));
      }
    } catch (error) {
      console.error('Error parsing stored sortedInfo:', error);
      localStorage.removeItem('sortedInfo');
    }

    if (storedShowAvailableOnly !== undefined) {
      setAndStoreShowAvailableOnly(JSON.parse(storedShowAvailableOnly));
    }
  }, [selectedCharacter]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/questtracker',
      title: 'Quest Tracker',
    });
  }, []);

  useEffect(() => {
    handleShowAvailableOnlyChange(showAvailableOnly);
  }, [showAvailableOnly, questDataArray]);

  const updateFilteredInfo = (filters) => {
    setFilteredInfo(filters);
    localStorage.setItem('filteredInfo', JSON.stringify(filters));
  };
  const updateSortedInfo = (sorter) => {
    setSortedInfo(sorter);
    localStorage.setItem('sortedInfo', JSON.stringify(sorter));
  };

  const updateExpandedRowKeys = (keys) => {
    setExpandedRowKeys(keys);
    localStorage.setItem('expandedRowKeys', JSON.stringify(keys));
  };

  const doSearch = () => {
    const filteredData = questDataArray.filter((item) => {
      return hasSubstringInObject(item, searchQuery.toLowerCase());
    });

    // Apply "Show Available Only" filter if enabled
    setSearchFilteredQuestData(filteredData);
  };

  // Recursive function to check if object has substring
  const hasSubstringInObject = (obj, query) => {
    for (let key in obj) {
      const value = obj[key];
      if (typeof value === 'string' && value.toLowerCase().includes(query)) {
        return true; // Return true if substring found
      } else if (
        typeof value === 'object' &&
        hasSubstringInObject(value, query)
      ) {
        return true; // Recursively check nested objects
      }
    }
    return false; // Return false if no substring found
  };

  const handleChange = (pagination, filters, sorter) => {
    updateFilteredInfo({
      ...filters,
    });
    updateSortedInfo(sorter);
    setSavedPagination(pagination); // Update state with current pagination
    localStorage.setItem('questPagination', JSON.stringify(pagination));
  };

  const clearFilters = () => {
    updateFilteredInfo({});
  };
  const clearAll = () => {
    setSearchQuery('');
    updateFilteredInfo({});
    updateSortedInfo({});
    setSearchFilteredQuestData(JSON.parse(JSON.stringify(questDataArray)));
  };

  const expandAll = () => {
    const allRowKeys = questDataArray.map((record) => record.name);
    updateExpandedRowKeys(allRowKeys);
  };

  const collapseAll = () => {
    updateExpandedRowKeys([]);
  };

  useEffect(() => {}, [selectedCharacter, questDataArray]);
  const updateQuestData = (newQuestData) => {
    const anotherCopy = JSON.parse(JSON.stringify(newQuestData));
    // Update the specific character
    const updatedCharacters = characters.map((character) =>
      character.name === selectedCharacter
        ? {
            ...character,
            questData: anotherCopy,
            dataVersion: currentQuestDataVersion,
          }
        : character,
    );
    setAndStoreCharacters(updatedCharacters);

    setQuestDataArray(JSON.parse(JSON.stringify(newQuestData)));
  };

  const setAndStoreShowAvailableOnly = (value) => {
    localStorage.setItem('showAvailableOnly', JSON.stringify(value));
    setShowAvailableOnly(value);
  };
  const handleShowAvailableOnlyChange = (checked) => {
    if (checked) {
      // Apply "Show Available Only" filter
      const availableData = questDataArray.filter((record) =>
        isAvailable(record, questDataArray),
      );
      setAvailableFilteredQuestData(availableData);
    } else {
      setAvailableFilteredQuestData(JSON.parse(JSON.stringify(questDataArray)));
    }
  };

  const handleCountCompletedUpdate = (
    taskName,
    countCompleted,
    objectiveType,
    name,
  ) => {
    const questDataArrayCopy = JSON.parse(JSON.stringify(questDataArray));
    const idxQuest = questDataArrayCopy.findIndex(
      (obj) => obj.name === taskName,
    );
    const questTypeObj = questDataArrayCopy[idxQuest].objectives[objectiveType];
    const idxSubTask = questTypeObj.findIndex((obj) => obj.name === name);
    questTypeObj[idxSubTask].countCompleted = countCompleted;

    updateQuestData(questDataArrayCopy);
  };

  const findIdenticalRecords = (filteredArray1, filteredArray2) => {
    const map1 = new Map(filteredArray1.map((item) => [item.name, item]));
    const map2 = new Map(filteredArray2.map((item) => [item.name, item]));
    const matches = [];

    for (const name of map1.keys()) {
      if (map2.has(name)) {
        matches.push(map1.get(name));
      }
    }
    return matches;
  };
  const createObjectiveInputs = (record) => {
    if (record.completed) {
      return (
        <Row style={{ marginBottom: 5 }}>
          {' '}
          <i>{'All Tasks Completed'}</i>
        </Row>
      );
    }

    return (
      <React.Fragment>
        {Object.keys(record.objectives).map((objectiveKey, index) => (
          <React.Fragment key={index}>
            {record.objectives[objectiveKey].map((task, taskIndex) => (
              <TaskTableItem
                completed={record.completed}
                taskName={record.name}
                key={taskIndex}
                name={task.name}
                count={task.count}
                countCompleted={task.countCompleted || 0}
                objectiveType={objectiveKey}
                map={task.map}
                handleCountCompletedUpdate={handleCountCompletedUpdate}
                consecEscape={task.consecutiveEscape}
                noDamage={task.noDamage}
                mustEscape={task.mustEscape}
                singleSession={task.singleSession}
                requiredLootState={task.requiredLootedState}
                rarity={task.rarity}
                killGrade={task.killGrade}
              />
            ))}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  const getTagColor = (mapName) => {
    const tagColors = {
      ruins: '#DAA520',
      crypts: '#927143',
      goblincaves: '#34533d',
      iceabyss: '#202b58',
      icecavern: '#5b7a92',
      inferno: '#5f0505',
      any: '#5D3FD3',
    };
    if (mapName in tagColors) {
      return tagColors[mapName];
    }
    return 'red';
  };
  const getFormattedMapName = (mapTag) => {
    const mapNames = {
      crypts: 'Crypts',
      goblincaves: 'Goblin Caves',
      iceabyss: 'Ice Abyss',
      icecavern: 'Ice Cavern',
      inferno: 'Inferno',
      ruins: 'Ruins',
      any: 'Any',
    };
    if (mapTag in mapNames) {
      return mapNames[mapTag];
    }
    return mapTag;
  };
  const columns = [
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      key: 'merchant',
      width: '130px',
      filteredValue: filteredInfo.merchant || null,
      filters: merchantFilters,
      onFilter: (value, record) => record.merchant.includes(value),
      sorter: (a, b) => a.merchant.localeCompare(b.merchant),
      sortOrder: sortedInfo.columnKey === 'merchant' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {record.confirmed === false && (
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <QuestNotConfirmedWarningIcon />
              </div>
            )}
            <MerchantAvatar name={record.merchant} />
          </div>
        );
      },
    },
    // {
    //   title: 'Chapter',
    //   key: 'chapter',
    //   dataIndex: 'chapter',
    //   width: '100px',
    //   ellipsis: true,
    //   render: (text, record) => {
    //     return '???';
    //   },
    // },
    {
      title: 'Quest Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Map',
      dataIndex: 'maptags',
      key: 'maptags',
      width: '130px',
      filteredValue: filteredInfo.maptags || null,
      filters: [
        { text: 'Crypts', value: 'crypts' },
        { text: 'Goblin Caves', value: 'goblincaves' },
        { text: 'Ice Abyss', value: 'iceabyss' },
        { text: 'Ice Cavern', value: 'icecavern' },
        { text: 'Inferno', value: 'inferno' },
        { text: 'Ruins', value: 'ruins' },
        { text: 'Any', value: 'any' },
      ],
      onFilter: (value, record) => {
        if (record.mapTags.includes('any')) {
          return true; // Always show the record if "any" is included in maptags
        } else if (value === 'any') {
          return true; // Show all records when the filter value is "any"
        } else {
          return record.mapTags.includes(value); // Filter based on record.maptags
        }
      },
      ellipsis: true,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {record.mapTags.map((mapTag) => (
              <Tag
                key={`${record.name}-${mapTag}`}
                style={{ margin: 4 }}
                color={getTagColor(mapTag)}
              >
                {getFormattedMapName(mapTag)}
              </Tag>
            ))}
          </div>
        );
      },
    },

    // {
    //   title: 'Prerequisite Quest',
    //   key: 'preReq',
    //   dataIndex: 'preReq',
    //   width: '15%',
    //   ellipsis: true,
    // },
    {
      title: 'Objective',
      dataIndex: 'objective',
      key: 'objective',
      render: (text, record) => {
        return createObjectiveInputs(record);
      },
      filteredValue: filteredInfo.objective || null,
      onFilter: (value, record) => {
        const { objectives } = record;
        const objectiveKeys = Object.keys(objectives);

        // Check if any of the objective keys match the filter value
        return objectiveKeys.some((key) => key === value);
      },
      filters: [
        { text: 'Kill', value: 'kill' },
        { text: 'Use', value: 'use' },
        { text: 'Collect', value: 'collect' },
        { text: 'Escape', value: 'survive' },
        { text: 'Explore', value: 'explore' },
        { text: 'Interact', value: 'open' },
        { text: 'Destroy', value: 'destroy' },
      ],
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      width: '150px',
      filteredValue: filteredInfo.completed || null,
      onFilter: (value, record) => {
        if (record.completed === undefined) {
          // If record.completed is undefined, assume it's not completed
          return !value; // Check if it's "Not Completed"
        }
        return record.completed === value;
      },
      filters: [
        { text: 'Completed', value: true },
        { text: 'Not Completed', value: false },
      ],
      render: (text, record) => {
        const onCompletedChanged = (e, completed) => {
          e.stopPropagation();
          const questDataArrayCopy = JSON.parse(JSON.stringify(questDataArray));
          const idxQuest = questDataArrayCopy.findIndex(
            (obj) => obj.name === record.name,
          );
          questDataArrayCopy[idxQuest].completed = completed;

          // Update the state directly without invoking a separate function

          updateQuestData(questDataArrayCopy);
          // Call handleShowAvailableOnlyChange to reevaluate available quests
          setAndStoreShowAvailableOnly(showAvailableOnly);
        };

        const completed = record.completed;
        const btnColor = 'rgba(82,196,26, 0.8)';
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={(e) => onCompletedChanged(e, !completed)}
              defaultChecked={completed}
              type={completed ? 'primary' : 'dashed'}
              style={{
                // color: completed ? '' : btnColor,
                // backgroundColor: completed ? btnColor : '',
                // borderColor: btnColor,
                width: 100,
              }}
            >
              {completed ? 'Completed' : 'Complete'}
            </Button>
          </div>
        );
      },
    },

    {
      title: 'Favs',
      dataIndex: 'favorited',
      key: 'favorited',
      width: '100px',
      filteredValue: filteredInfo.favorited || null,
      onFilter: (value, record) => {
        if (record.favorited === undefined) {
          // If record.completed is undefined, assume it's not completed
          return !value; // Check if it's "Not Completed"
        }
        return record.favorited === value;
      },
      filters: [
        { text: 'Favorite', value: true },
        { text: 'Not favorite', value: false },
      ],
      sorter: (a, b) =>
        a.favorited === b.favorited ? 0 : a.favorited ? -1 : 1,
      sortOrder: sortedInfo.columnKey === 'favorited' ? sortedInfo.order : null,
      render: (text, record) => {
        const onFavoriteChanged = (e, favorited) => {
          e.stopPropagation();
          const questDataArrayCopy = JSON.parse(JSON.stringify(questDataArray));
          const idxQuest = questDataArrayCopy.findIndex(
            (obj) => obj.name === record.name,
          );
          questDataArrayCopy[idxQuest].favorited = favorited;

          // Update the state directly without invoking a separate function

          updateQuestData(questDataArrayCopy);
          // Call handleShowAvailableOnlyChange to reevaluate available quests
          setAndStoreShowAvailableOnly(showAvailableOnly);
        };

        const favorited = record.favorited;
        const btnColor = '#76ABAE';
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={(e) => onFavoriteChanged(e, !favorited)}
              defaultChecked={favorited}
              shape="default"
              value="small"
              icon={favorited ? <StarFilled /> : <StarOutlined />}
              style={{
                backgroundColor: favorited ? btnColor : '',
              }}
            ></Button>
          </div>
        );
      },
    },
  ];
  const resetQuestProgress = () => {
    const storedData = localStorage.getItem('characters');
    if (!storedData) return;
    const characters = storedData ? JSON.parse(storedData) : [];
    const index = characters.findIndex(
      (character) => character.name === selectedCharacter,
    );

    if (index !== -1) {
      // Remove the questData field from the specific character
      const updatedCharacter = { ...characters[index] };
      delete updatedCharacter.questData;

      // Update the character in the array
      characters[index] = updatedCharacter;

      // Save the updated data back to local storage
      localStorage.setItem('characters', JSON.stringify(characters));
    }

    localStorage.removeItem('questData');
    window.location.reload();
  };
  if (isMobile) {
    return (
      <Card>
        <Title level={5}>
          Quest Tracker is not currently supported on mobile devices
        </Title>

        <Paragraph>
          View this page on your <b>desktop</b> browser for the best Quest
          tracking Experience{' '}
        </Paragraph>
        <Paragraph>
          If you still want to track quest items go to the{' '}
          <Link to={questItemsPath}>Quest Item page</Link>
        </Paragraph>
        <img
          src={SorryImage}
          alt={'Sorry'}
          style={{ height: 50, width: 50 }}
        ></img>
      </Card>
    );
  }

  return (
    <div>
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <Popconfirm
          title="Reset All Quest Progress"
          description="Are you want to reset all Quest Progress?"
          onConfirm={resetQuestProgress}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <FloatButton
            tooltip={<div>Reset all Quest Progress</div>}
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
        <FloatButton
          tooltip={<div>Discord server</div>}
          icon={<DiscordOutlined />}
          href="https://discord.gg/Czx6xMq5rN"
          target="_blank"
        />
      </FloatButton.Group>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        {/* <Row style={{ width: '100%', maxWidth: 1200, marginBottom: 10 }}>
          <Alert
            message="Warning: This tool is currently undergoing experimental testing, so please be aware that you may encounter a few bugs. If you happen to identify any issues, kindly reach out to us via Discord. If your data becomes corrupted, please utilize the reset button located in the bottom right corner. We apologize for any inconveniences."
            type="warning"
            closable
            style={
              {
                // minHeight: 100,
                // position: 'fixed',
                // left: '50%',
                // top: '20px',
                // transform: 'translate(-50%, 0)',
                // zIndex: '9999',
              }
            }
            // onClose={()}
          />
        </Row> */}
        <Row style={{ width: '100%', maxWidth: 1200, marginBottom: 10 }}>
          <Col span={18}>
            <Switch
              checked={showAvailableOnly}
              onChange={() => {
                setAndStoreShowAvailableOnly(!showAvailableOnly);
              }}
              checkedChildren="Available Only"
              unCheckedChildren="Show All"
              className="button-margin"
            />
            <Button onClick={clearFilters} className="button-margin">
              Clear filters
            </Button>
            <Button onClick={clearAll} className="button-margin">
              Clear filters and sorters
            </Button>
            <Button onClick={expandAll} className="button-margin">
              Expand All
            </Button>
            <Button onClick={collapseAll} className="button-margin">
              Collapse All
            </Button>
          </Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Search
              placeholder="Search..."
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={doSearch}
              value={searchQuery}
              enterButton
            />
          </Col>
        </Row>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Table
          columns={columns}
          dataSource={findIdenticalRecords(
            availableFilteredQuestData,
            searchFilteredQuestData,
          )}
          scroll={{ x: 1000 }}
          onChange={handleChange}
          rowKey={(record) => record.name}
          pagination={savedPagination}
          style={{ maxWidth: 1200, minWidth: 500 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(expanded, record) => {
            if (expanded) {
              updateExpandedRowKeys([...expandedRowKeys, record.name]);
            } else {
              updateExpandedRowKeys(
                expandedRowKeys.filter((key) => key !== record.name),
              );
            }
          }}
          expandable={{
            expandedRowRender: (record) => createRewardsSection(record),
            rowExpandable: (record) => record.name !== 'Not Expandable',
            expandRowByClick: true,
          }}
          summary={() => <Table.Summary fixed="top" />}
          sticky={{ offsetHeader: -1 }} // Adjust the offsetHeader as needed
        />
      </Row>
    </div>
  );
}

export default QuestTracker;
