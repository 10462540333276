/* eslint-disable react/prop-types */
import {
  List,
  Card,
  Row,
  Col,
  Button,
  Typography,
  FloatButton,
  notification,
  Switch,
  Flex,
  Tooltip,
} from 'antd';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import QuestData from '../../data/all_quests.json';
import { currentQuestDataVersion } from '../../data/versionInfo';
import { getRarityColor } from '../../shared/text';
import { isMobile } from 'react-device-detect';
import { isAvailable } from './questShared';
import QuestNotConfirmedWarningIcon from './questNotConfirmedWarningIcon';
import {
  PlusOutlined,
  MinusOutlined,
  DiscordOutlined,
} from '@ant-design/icons';
import ItemAvatar from './itemAvatar';
import { processUpdatedQuests } from './processUpdatedQuests';
const { Title } = Typography;
function QuestItems({ selectedCharacter, characters, setAndStoreCharacters }) {
  const [questDataArray, setQuestDataArray] = useState([]);
  const [collectedItemsArray, setCollectedItemsArray] = useState([]);
  const [itemsToCollectArray, setItemsToCollectArray] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [compactMode, setCompactMode] = useState(false);
  const [alphabeticalMode, setAlphabeticalMode] = useState(false);

  useEffect(() => {
    const storedCompactMode = localStorage.getItem('questItemsCompactMode');
    const storedAlphaMode = localStorage.getItem('questItemsAlphaMode');

    if (storedCompactMode) {
      setCompactMode(storedCompactMode === 'true');
    }
    if (storedAlphaMode) {
      setAlphabeticalMode(storedAlphaMode === 'true');
    }
    const selectedCharObj = characters.find(
      (character) => character.name === selectedCharacter,
    );

    if (selectedCharObj?.questData) {
      const storedQuests = selectedCharObj.questData;

      const storedVersion = selectedCharObj.dataVersion;
      if (storedVersion < 25) {
        // wipee reset all progress
        updateQuestData(QuestData['quests']);
        return;
      }
      if (storedVersion == currentQuestDataVersion) {
        updateQuestData(storedQuests);
      }
      const updatedQuests = processUpdatedQuests(storedQuests, storedVersion);
      updateQuestData(updatedQuests);
    } else {
      updateQuestData(QuestData['quests']);
    }
  }, [selectedCharacter]);

  useEffect(() => {
    const newCollectedItemsArray = [];
    const newItemsToCollectItemsArray = [];
    questDataArray.forEach((quest, index) => {
      if (quest.objectives.collect) {
        quest.objectives.collect.forEach((objective) => {
          objective.completed = quest.completed === true;
          objective.questName = quest.name;
          objective.confirmed = quest.confirmed;
          objective.available = isAvailable(quest, questDataArray);
          if (
            quest.completed === true ||
            (objective.countCompleted &&
              objective.countCompleted == objective.count)
          ) {
            newCollectedItemsArray.push(objective);
          } else {
            newItemsToCollectItemsArray.push(objective);
          }
        });
      }
    });
    newItemsToCollectItemsArray.sort(compareItems);
    newCollectedItemsArray.sort(compareItems);
    setCollectedItemsArray(
      alphabeticalMode
        ? newCollectedItemsArray
        : newCollectedItemsArray.reverse(),
    );
    setItemsToCollectArray(newItemsToCollectItemsArray);
  }, [questDataArray, alphabeticalMode]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/questitems',
      title: 'Quest Items',
    });
  }, []);

  const questItemChangeWarning = () => {
    api['warning']({
      message: 'Warning',
      description:
        'You cannot edit collected item counts when a quest is already marked as completed',
      className: 'custom-class',
      style: {
        width: 600,
      },
    });
  };

  const compareItems = (a, b) => {
    if (alphabeticalMode) {
      return a.name.localeCompare(b.name);
    }

    if (a.available && !b.available) {
      return -1; // a is available, b is not available
    } else if (!a.available && b.available) {
      return 1; // a is not available, b is available
    } else {
      return 0; // Both are either available or not available, maintain their relative order
    }
  };
  const updateQuestData = (newQuestData) => {
    const anotherCopy = JSON.parse(JSON.stringify(newQuestData));
    // Update the specific character
    const updatedCharacters = characters.map((character) =>
      character.name === selectedCharacter
        ? {
            ...character,
            questData: anotherCopy,
            dataVersion: currentQuestDataVersion,
          }
        : character,
    );

    setAndStoreCharacters(updatedCharacters);
    setQuestDataArray(JSON.parse(JSON.stringify(newQuestData)));
  };

  const handleCountCompletedUpdate = (itemName, questName, countCompleted) => {
    const questDataArrayCopy = [...questDataArray];
    const idxQuest = questDataArrayCopy.findIndex(
      (obj) => obj.name === questName,
    );
    const questTypeObj = questDataArrayCopy[idxQuest].objectives['collect'];
    const idxSubTask = questTypeObj.findIndex((obj) => obj.name === itemName);
    questTypeObj[idxSubTask].countCompleted = countCompleted;
    updateQuestData(questDataArrayCopy);
  };
  const incrementCountCompleted = (
    e,
    countCompleted,
    count,
    questName,
    itemName,
  ) => {
    e.stopPropagation();
    countCompleted = countCompleted === undefined ? 0 : countCompleted;
    const incrementedCount = countCompleted + 1;
    if (incrementedCount > count) return;
    handleCountCompletedUpdate(itemName, questName, incrementedCount);
  };
  const decrementCountCompleted = (
    e,
    countCompleted,
    count,
    questName,
    itemName,
  ) => {
    e.stopPropagation();
    countCompleted = countCompleted === undefined ? 0 : countCompleted;
    const decrementedCount = countCompleted - 1;
    if (decrementedCount < 0) return;
    handleCountCompletedUpdate(itemName, questName, decrementedCount);
  };

  const getFullDetailedCard = (objective) => (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: 4,
          }}
        >
          <span style={{ marginRight: 8 }}>
            <ItemAvatar width={50} name={objective.name} />
          </span>
          <span style={{}}>{objective.name}</span>
          {objective.confirmed === false && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                marginRight: 8,
                // transform: 'translateY(50%) translateX(-50%)',
              }}
            >
              <QuestNotConfirmedWarningIcon />
            </div>
          )}
        </div>
      }
      actions={[
        <MinusOutlined
          onClick={(e) => {
            if (objective.completed) {
              questItemChangeWarning();
              return;
            }
            decrementCountCompleted(
              e,
              objective.countCompleted,
              objective.count,
              objective.questName,
              objective.name,
            );
          }}
        />,

        <b
          style={{
            fontSize: 18,
          }}
        >
          {objective.completed
            ? objective.count
            : objective.countCompleted == undefined
              ? 0
              : objective.countCompleted}
          /{objective.count}
        </b>,
        <PlusOutlined
          onClick={(e) => {
            if (objective.completed) {
              questItemChangeWarning();
              return;
            }
            incrementCountCompleted(
              e,
              objective.countCompleted,
              objective.count,
              objective.questName,
              objective.name,
            );
          }}
        />,
      ]}
    >
      <div>Quest name: {objective.questName}</div>
      <div>
        Rarity:{' '}
        <span
          style={{
            color: getRarityColor(
              objective.rarity ? objective.rarity : objective.name,
            ),
          }}
        >
          {objective.rarity ? (
            <span>&nbsp;{objective.rarity}</span>
          ) : (
            <span>Any</span>
          )}
        </span>
      </div>
      <div>
        Loot State:{' '}
        {objective.requiredLootedState ? <b>Looted</b> : <span>Any</span>}
      </div>
      {!isMobile && (
        <div>
          Quest Status:{' '}
          {objective.completed ? (
            <span style={{ color: '#52c41a' }}>Completed</span>
          ) : (
            <span
              style={{
                color: objective.available ? '#faad14' : '#ff4d4f',
              }}
            >
              {objective.available === true ? 'In Progress' : 'Locked'}
            </span>
          )}
        </div>
      )}
    </Card>
  );
  const getCardSection = (objectiveArray, sectionTitle) => (
    <div>
      <Title>{sectionTitle}</Title>
      {compactMode ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {objectiveArray.map((objective) => {
            return (
              <Tooltip
                title={getFullDetailedCard(objective)}
                overlayStyle={{ maxWidth: '500px' }}
              >
                <Card
                  bodyStyle={{ padding: 0 }} // Remove padding from body
                  style={{ width: 150 }}
                  key={objective.questName + objective.name}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <span>
                        <ItemAvatar width={50} name={objective.name} />
                      </span>
                      <span>
                        <b
                          style={{
                            fontSize: 18,
                            color: getRarityColor(
                              objective.rarity
                                ? objective.rarity
                                : objective.name,
                            ),
                          }}
                        >
                          {objective.completed
                            ? objective.count
                            : objective.countCompleted == undefined
                              ? 0
                              : objective.countCompleted}
                          /{objective.count}
                        </b>
                      </span>

                      {objective.confirmed === false && (
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            marginRight: 8,
                            // transform: 'translateY(50%) translateX(-50%)',
                          }}
                        >
                          <QuestNotConfirmedWarningIcon />
                        </div>
                      )}
                    </div>
                  }
                  actions={[
                    <MinusOutlined
                      onClick={(e) => {
                        if (objective.completed) {
                          questItemChangeWarning();
                          return;
                        }
                        decrementCountCompleted(
                          e,
                          objective.countCompleted,
                          objective.count,
                          objective.questName,
                          objective.name,
                        );
                      }}
                    />,

                    <PlusOutlined
                      onClick={(e) => {
                        if (objective.completed) {
                          questItemChangeWarning();
                          return;
                        }
                        incrementCountCompleted(
                          e,
                          objective.countCompleted,
                          objective.count,
                          objective.questName,
                          objective.name,
                        );
                      }}
                    />,
                  ]}
                />
              </Tooltip>
            );
          })}
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          <React.Fragment>
            {objectiveArray.map((objective) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  key={objective.questName + objective.name}
                >
                  {getFullDetailedCard(objective)}
                </Col>
              );
            })}
          </React.Fragment>
        </Row>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {contextHolder}
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          tooltip={<div>Discord server</div>}
          icon={<DiscordOutlined />}
          href="https://discord.gg/Czx6xMq5rN"
          target="_blank"
        />
      </FloatButton.Group>

      <div className="center-contents">
        <div className="max-width-container ">
          <div>
            <Switch
              checked={compactMode}
              onChange={(val) => {
                setCompactMode(val);
                localStorage.setItem('questItemsCompactMode', val);
              }}
              checkedChildren="Compact"
              unCheckedChildren="Detailed"
              className="button-margin"
            />
            <Switch
              checked={alphabeticalMode}
              onChange={(val) => {
                setAlphabeticalMode(val);
                localStorage.setItem('questItemsAlphaMode', val);
              }}
              checkedChildren="Alphabetical"
              unCheckedChildren="Available First"
              className="button-margin"
            />
            {getCardSection(itemsToCollectArray, 'Items to Collect')}
            {getCardSection(collectedItemsArray, 'Collected')}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default QuestItems;
